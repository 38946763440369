import type { AppProps } from "next/app";
import { SessionProvider, useSession } from "next-auth/react";
import router from "next/router";

import "../styles/style.scss";

interface IProps {
  Component: AppProps["Component"] & { auth?: boolean };
  pageProps: AppProps["pageProps"];
}

function MyApp({ Component, pageProps }: IProps) {
  return (
    <SessionProvider session={pageProps.session}>
      {Component.auth ? (
        <Auth>
          <Component {...pageProps} />
        </Auth>
      ) : (
        <Component {...pageProps} />
      )}
    </SessionProvider>
  );
}

export default MyApp;

function Auth({ children }: any) {
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status } = useSession({ required: true });

  if (status === "loading") {
    return <div>Loading...</div>;
  } else {
    router.push("/");
  }

  return children;
}
